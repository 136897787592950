import React, { Component } from "react";
// import { Redirect } from 'react-router-dom';
// import axios from 'axios';
import $ from "jquery";
import "jquery-ui";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/themes/base/datepicker.css";
import Select from "react-select";
// import Datetime from 'react-datetime';
import Modal from "react-responsive-modal";
import "react-datetime/css/react-datetime.css";
import SweetAlert from "react-bootstrap-sweetalert";
// import Constant from "../common/constants";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import CSVFileValidator from "csv-file-validator";
import PapaParse from "papaparse";
import { getHyphenDDMMMYYYYHHMM } from "../common/utils";
import { verifyext } from "../common/verifyext";
import getToolConfiguration from "./commonFunctions";
import redirectURL from "../redirectURL";
import GridButton from "./gridButton";
import NAButton from "./naButton";
import StaticButton from "./static-button";
import FlagIcon from "./fontFlag";
import OrderButton from "./orderviewbtn";
import PlanFollowedButton from "./planfollowbtn";
import CountDownTimer from "./timerComponent";
import vehicleAssigned from "./vehicleAssigned";
import * as XLSX from "xlsx";
import Consolidatedbtn from "./consolidatedbtn.js";

var infoBox = require("../common/google-infowindow");
const scaleanimation = {
  transition: "all 1s easy-out",
};

var moment = require("moment");
var markersArray;
var map;
var mapcluster;

var overlay;
var infowindows = [];
var markersArraylist = [];

export default class DispatchPlanHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
        NAButton: NAButton,
        FlagIcon: FlagIcon,
        OrderButton: OrderButton,
        PlanFollowedButton: PlanFollowedButton,
        vehicleAssigned: vehicleAssigned,
        Consolidatedbtn: Consolidatedbtn,
      },
      rowData: [],
      latestData: [],
      selectedFile: null,
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 1000,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      showSlideBlockRoute: "sidebarcls",
      showSlideBlockUpload: "sidebarcls",
      sliderRso: "sidebarcls",
      showSlideBlockStack: "sidebarcls",
      overlayBlock: "show-n",
      routeData: [],
      rfreshmap: 1,
      csvcontent: [],
      deliverpickupslist: [],
      dealers: [],
      plants: [],
      uploadfile: "",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      loadplan: [],
      allLoadPlan: [],
      deliverydates: [],
      deliveryopts: [],
      filterDeliveryDate: { value: "", label: "Select Delivery Date" },
      clusterlist: [],
      filtercluster: { value: "", label: "Select Cluster" },
      vehicletypelist: [],
      filterVehicleType: { value: "", label: "All" },
      mapcol: "show-n",
      gridocl: "col-sm-12",
      filter_date: "",
      vtypediv: "show-n",
      vsupplierdiv: "show-n",
      selectedCluster: "",
      fdealerlist: [],
      filterdealer: { value: "", label: "All" },
      showloader: "show-n",
      mpnode: [],
      mpfor: "",
      clusterpoints: [],
      nmapcluster: "show-n",
      nmap: "show-m",
      scluster: "",
      stackContent: "",
      stackImage: "",
      stacktbl: [],
      stacktblbottom: [],
      stackOriginalData: [],
      showrunprocessbtn: "show-m",
      showanimate: "show-n",
      locationNames: [],
      loadFile: "",
      material_configurations: 0,
      location: { label: "Select Location", value: "" },
      loadshow: "show-m",
      overly: "show-m",
      processErrMsg: "",
      processErrShow: "",
      transactionId: "",
      droppedList: [],
      inputDetails: [],
      tool_config: [],
      defFlag: 0,
      planRowData: [],
      openSLA: false,
      sla: { value: "", label: "Select" },
      allTransactionIds: [],
      locationZones: [],
      dispatchType: { label: "All", value: "" },
      country: { label: "All", value: "" },
      pickupLocationCodes: {},
      boundLocations: [],
      zone: { label: "All", value: "" },
      modalForRunNew: false,
    };
    this.getDispatchPlanData = this.getDispatchPlanData.bind(this);
  }
  async componentDidMount() {
    $(".datepicker").datepicker({
      dateFormat: "dd-mm-yy",
      changeYear: true,
    });
    // $(".datepicker").val(moment.parseZone().format("DD-MM-YYYY"))
    let url_type = window.location.href;
    // console.log(url_type,"url_type")
    let path_type = url_type.split("?");
    // console.log(path_type,"path_type")
    let data_type;
    let path = path_type[path_type.length - 1];

    // console.log(path,"path")
    if (path === "inbound") {
      data_type = 1;
    } else {
      data_type = 2;
    }
    await this.setState({ data_type: data_type });
    let params = {
      data_type: data_type,
    };
    // var locationAcces=JSON.parse(localStorage.getItem('location_access'))

    // if (locationAcces!==null){
    //     params.plant_code=locationAcces[0]
    // }
    // console.log(params)
    let toolparameters = {
      data_type: data_type,
    };
    await getToolConfiguration(toolparameters);

    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    var material_configurations = 0;
    if (toolConfig != undefined && toolConfig != "") {
      if (toolConfig.length > 0) {
        material_configurations = toolConfig[0].material_configuration;
      }
    }
    this.setState({
      material_configurations: material_configurations,
    });
    await this.boundTypeLocations(params);
    await this.getDispatchPlanData();
    this.intervalId = setInterval(() => {
      this.getPendingTransactions(this.state.latestData);
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  onClickGetData = async () => {
    await this.getDispatchPlanData();
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on Get Data Button",
      event: "Data in aggrid filtered",
      data_type: this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  onUploadOBDDetails = async () => {
    this.setState({
      overlayBlock: "show-m",
      sliderRso: "slide25",
    });
  };
  getLatestData = async (rowData) => {
    var latestData = [];
    if (rowData.length > 0) {
      latestData = await rowData.filter((rd) => rd.latest_run === 1);
    }
    if (latestData.length) {
      this.getPendingTransactions(latestData);
    }
    await this.setState({ latestData });
  };

  getDispatchPlanData = () => {
    // var { zone, country } = this.state;
    // console.log("first zone", zone, country);
    // console.log("hit first");
    this.setState({ loadshow: "show-m", overlayBlock: "show-m" });
    setTimeout(() => {
      var selectDate = $("#selectDate").val();
      var generateDate = $("#generateDate").val();
      if (
        generateDate !== undefined &&
        generateDate !== "" &&
        selectDate !== undefined &&
        selectDate !== ""
      ) {
        if (selectDate < generateDate) {
          this.setState({
            show: true,
            basicTitle:
              "generated On Date should be less than or equal to Order Date",
            basicType: "danger",
            loadshow: "show-n",
            overlayBlock: "show-n",
          });
          return;
        }
      }
      // moment(req.body.selectDate,"DD-MM-YYYY").format("YYYY-MM-DD")
      if (selectDate !== undefined && selectDate !== "") {
        selectDate = moment(selectDate, "YYYY-MM-DD").format("DD-MM-YYYY");
      }
      if (generateDate !== undefined && generateDate !== "") {
        generateDate = moment(generateDate, "YYYY-MM-DD").format("DD-MM-YYYY");
      }

      // console.log("this.state.location.value ", 'this.state.locationNames')
      // if(this.state.location.value !== "")
      // {
      // this.state.zone,this.state.country
      var { zone, country, dispatchType } = this.state;
      var params;
      if (dispatchType.value) {
        dispatchType = dispatchType.value;
      } else {
        dispatchType = "";
      }
      // console.log("first zone", zone, dispatchType);
      try {
        if (this.state.defFlag === 0) {
          params = {
            data_type: this.state.data_type,
            location_code: this.state.locationNames[0].value,
            useremail: localStorage.getItem("email"),
            client: localStorage.getItem("client"),
            userType: localStorage.getItem("user_type"),
            zone: zone.value,
            country: country.value,
            dispatchType,
          };
        } else {
          params = {
            data_type: this.state.data_type,
            location_code: this.state.location.value,
            selectDate: selectDate,
            useremail: localStorage.getItem("email"),
            client: localStorage.getItem("client"),
            userType: localStorage.getItem("user_type"),
            generateDate,
            zone: zone.value,
            country: country.value,
            dispatchType,
          };
        }
      } catch (error) {}
      // console.log("first para", params);
      redirectURL
        .post("dispatch/getDispatchPlanData", params)
        .then(async (response) => {
          try {
            var records = response.data;
            if (records.length > 0) {
              var rowData = records.sort(GetSortDescOrder("order_date"));
              var latestData = [];
              // var otherData = [];
              var rowDataarr = [];
              if (rowData.length > 0) {
                // console.log("first records", latestData);
                // var latestData = await rowData.filter(
                //   (rd) => rd.latest_run == 1
                // );
                // var otherData = await rowData.filter(
                //   (rd) =>
                //     rd.latest_run === 0 || rd.latest_run === undefined
                // );
                await rowData.map((item) => {
                  var converoDate = moment
                    .parseZone(item.order_date)
                    .format("x");
                  item.orderpos = converoDate;
                  rowDataarr.push(item);
                  return true;
                });
              }
              // console.log("first records", latestData,otherData);
              // console.log("rowDataarr first", rowDataarr);
              if (rowDataarr.length > 0) {
                rowDataarr = rowDataarr.sort(GetSortDescOrder("orderpos"));
              }
              if (latestData.length > 0) {
                this.getPendingTransactions(latestData);
              }
              // console.log('first ro3w',rowData)
              this.getLatestData(rowData);
              this.setState(
                {
                  latestData: latestData,
                  rowData: rowData,
                  loadshow: "show-n",
                  overly: "show-n",
                  overlayBlock: "show-n",
                  defFlag: 1,
                }
                // (rowData)=>{
                //   console.log('first row',rowData)
                //   this.getLatestData(rowData);

                // }
              );
            } else {
              this.setState({
                rowData: [],
                loadshow: "show-n",
                overly: "show-n",
                overlayBlock: "show-n",
                defFlag: 1,
              });
            }
          } catch (error) {}
        })
        .catch(function (error) {
          var errDetails = {
            url: "dispatch/getDispatchPlanData",
            screen: "Summary Dashboard",
            error,
          };
          redirectURL.post("/master/logErrorsForApiCalls", errDetails);
        });

      // }
      // else{
      //     this.setState({
      //         rowData:[],
      //         loadshow:'show-n',
      //         overly:'show-n',
      //         overlayBlock:"show-n"
      //     })
      // }
    }, 300);
  };
  // start;

  // boundTypeLocations = async (param) => {
  //   let location_access = JSON.parse(localStorage.getItem("location_access"));

  //   let locationNames = this.state.locationNames;

  //   await redirectURL
  //     .post("master/getBoundTypeLocations", param)
  //     .then(async (response) => {
  //       if (response.data.status == "success") {
  //         let boundLocations = response.data.boundLocations;
  //         // locationNames.push({ value : "",
  //         //     label : "Select" })
  //         if (boundLocations.length > 0) {
  //           boundLocations.map((item) => {
  //             if (location_access != null) {
  //               if (location_access.includes(item.location_code)) {
  //                 locationNames.push({
  //                   value: item.location_code,
  //                   label: item.location_name,
  //                 });
  //               }
  //             } else {
  //               locationNames.push({
  //                 value: item.location_code,
  //                 label: item.location_name,
  //               });
  //             }
  //           });

  //           if (locationNames.length > 0) {
  //             await this.setState({
  //               locationNames: locationNames,
  //               location: locationNames[0],
  //             });
  //           } else {
  //             await this.setState({
  //               locationNames: [{ label: "Please Add Data", value: "no_data" }],
  //               location: [{ label: "Please Add Data", value: "no_data" }],
  //             });
  //           }
  //         }
  //       }
  //     })
  //     .catch(function (error) {
  //       var errDetails = {
  //         url: "master/getBoundTypeLocations",
  //         screen: "Summary Dashboard",
  //         error,
  //       };
  //       redirectURL.post("/master/logErrorsForApiCalls", errDetails);
  //     });
  // };
  // start;
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  onSubmitBulkUpload = (event) => {
    event.preventDefault();
    let modifiedUploadedDetails = this.state.csvcontent
      .filter((e) => e.location_code === this.state.location.value)
      .map((e) => {
        return {
          created_date: getHyphenDDMMMYYYYHHMM(new Date()),
          ...e,
          is_processed: 0,
        };
      });
    // console.log(modifiedUploadedDetails,"modifiedUploadedDetails")
    redirectURL
      .post("master/uploadOBDDetails", modifiedUploadedDetails)
      .then((resp) => {
        // console.log(resp.data,"response after uploading");
        if (resp.data.status === "success") {
          document.getElementById("uploadfile").value = null;
          this.setState({
            show: true,
            basicTitle: resp.data.message,
            basicType: "success",
            sliderRso: "",
            overlayBlock: "show-n",
          });
        } else {
          this.setState({
            show: true,
            basicTitle: resp.data.message,
            basicType: "danger",
            sliderRso: "",
            overlayBlock: "show-n",
          });
          document.getElementById("uploadfile").value = null;
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/uploadOBDDetails",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/uploadOBDDetails", errDetails);
      });
  };
  changeFileHandlerFc = async (e) => {
    let fileExtension = e.target.files[0].name.split(".").pop();
    if (fileExtension === "xlsx" || fileExtension === "csv") {
      this.setState(
        {
          selectedFile: e.target.files[0],
        },
        () => {
          if (this.state.selectedFile) {
            if (fileExtension === "xlsx") {
              const fileReader = new FileReader();
              fileReader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: "array" });
                const csvData = XLSX.utils.sheet_to_csv(
                  workbook.Sheets[workbook.SheetNames[0]]
                );
                this.validateCSVData(csvData);
              };
              fileReader.readAsArrayBuffer(this.state.selectedFile);
            } else if (fileExtension === "csv") {
              PapaParse.parse(this.state.selectedFile, {
                complete: (results) => {
                  const csvData = PapaParse.unparse(results.data);
                  console.log(csvData, "csvDatacsvData");
                  this.validateCSVData(csvData);
                },
              });
            }
          }
        }
      );
    } else {
      document.getElementById("uploadfile").value = null;
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Please upload file having extensions xlsx or csv only.",
      });
    }
  };

  validateCSVData = (csvData) => {
    const config = {
      headers: [
        {
          name: "Pickup_Location_Code",
          inputName: "location_code",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "order_number",
          inputName: "order_number",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
      ],
    };

    CSVFileValidator(csvData, config)
      .then((csvContent) => {
        // console.log(csvContent,"GGGGGGGGGGG")
        if (csvContent.inValidData.length === 0) {
          // Additional validation for alphanumeric characters
          // console.log(csvContent.data,"CSV Content")
          const dataErrors = [];
          if (csvContent.data.length === 0) {
            dataErrors.push("1");
          }
          csvContent.data.forEach((row, rowIndex) => {
            Object.keys(row).forEach((key, columnIndex) => {
              const value = row[key];
              if (!/^[a-zA-Z0-9]+$/.test(value)) {
                dataErrors.push({
                  rowIndex: rowIndex + 2,
                  columnIndex: columnIndex + 1,
                  message: `${key} contains invalid characters in the ${
                    rowIndex + 2
                  } row / ${columnIndex + 1} column`,
                });
              }
              if (
                key === "location_code" &&
                value !== this.state.location.value
              ) {
                dataErrors.push({
                  rowIndex: rowIndex + 2,
                  columnIndex: columnIndex + 1,
                  message: `location_code does not match in the ${
                    rowIndex + 2
                  } row / ${columnIndex + 1} column`,
                });
              }
            });
          });

          if (dataErrors.length > 0) {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Please correct the invalid data",
              errors: dataErrors,
            });
            document.getElementById("uploadfile").value = null;
          } else {
            this.setState(
              {
                csvcontent: csvContent.data,
              },
              () => {
                // console.log("Valid data is found first");
              }
            );
          }
        } else {
          document.getElementById("uploadfile").value = null;
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Please upload file having valid Details",
            errors: csvContent.inValidData,
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  onClickShowUpload = () => {
    // this.setState({
    //     showSlideBlockUpload:"slide25",
    //     overlayBlock:"show-m"
    // })
    let url_type = window.location.href;
    let path_type = url_type.split("?");
    let path = path_type[path_type.length - 1];
    window.location.href = "loadprocessrun?" + path;
  };

  hideSlideBlock = () => {
    this.setState({
      overlayBlock: "show-n",
      showSlideBlock: "",
      showSlideBlockUpload: "",
      showSlideBlockRoute: "",
      showSlideBlockStack: "",
      loadFile: "",
      loadshow: "show-n",
      overly: "show-n",
      sliderRso: "",
      loadFile: "",
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
  };
  fixProcess = () => {
    this.setState({
      processErrMsg: "",
      processErrShow: 0,
      loadFile: "",
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    //document.getElementById("upform").reset();
  };
  ignoreProcessError = () => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    var transactionId = this.state.transactionId;
    // console.log("transactionId",transactionId)
    var params = {
      transactionId: transactionId,
      data_type: this.state.data_type,
    };

    redirectURL
      .post("dispatch/generateLoadByTransactionId", params)
      .then((response) => {
        //console.log(response.data,"response")
        var statusCode = response.data.status;
        if (statusCode == 0 || statusCode == 1) {
          this.setState({
            basicTitle: response.data.message,
            basicType: "success",
            show: true,
            processErrMsg: "",
            processErrShow: 0,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
            showSlideBlockUpload: "",
          });
        } else {
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        }
        //window.location.reload();
      })
      .catch(function (error) {
        var errDetails = {
          url: "dispatch/generateLoadByTransactionId",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  changeFileHandler = async (e) => {
    // console.log(e);
    this.setState({
      loadFile: e.target.files[0],
    });

    var check = verifyext(e.target.files[0].name);
    //console.log("Check ", check)
    if (check == true) {
      if (
        e.target.files[0].type == "" ||
        e.target.files[0].type == "text/csv" ||
        e.target.files[0].type == "application/vnd.ms-excel"
      ) {
        // console.log(fileData);
        // console.log(typeof(fileData))
      } else {
        e.target.value = null;
        this.setState({
          uploadFile: "",
          show: true,
          basicType: "danger",
          basicTitle: "Please upload file having extensions .csv only.",
        });
      }
    } else {
      e.target.value = null;
      this.setState({
        uploadFile: "",
        show: true,
        basicType: "danger",
        basicTitle: "Please upload file having extensions .csv only.",
      });
    }
  };

  uploadBulkFormHandler(event) {
    event.preventDefault();
    var flag = 0;
    let saveData = new FormData();
    if (flag == 0) {
      saveData.append("loadFile", this.state.loadFile);
      var notify_email = $("#notify_email").val();
      saveData.append("notify_email", notify_email);
      saveData.append("module_type", 1);
      saveData.append("data_type", this.state.data_type);
      saveData.append(
        "material_configurations",
        this.state.material_configurations
      );
      let location = this.state.location;
      saveData.append("location_code", location.value);
      saveData.append("location_name", location.label);
      saveData.append("user_name", localStorage.getItem("username"));
      saveData.append("email", localStorage.getItem("email"));
      console.log(saveData, saveData);
      this.setState({
        loadshow: "show-m",
        overly: "show-m",
      });

      redirectURL
        .post("/dispatch/generateLoad", saveData, {
          headers: {
            "content-type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Headers": "Origin, Content-Type",
          },
        })
        .then((response) => {
          console.log(response, "responseFromProcess");
          var statusCode = response.data.status;
          if (statusCode == 8) {
            this.setState({
              processErrMsg: response.data.message,
              processErrShow: 1,
              transactionId: response.data.transactionId,
              droppedList: response.data.droppedList,
              statusCode: statusCode,
              loadshow: "show-n",
              overly: "show-n",
              overlayBlock: "show-n",
            });
          } else if (statusCode > 3 && statusCode != 8) {
            if (statusCode == 11) {
              var processMsg =
                "Use the standard input data file template for uploading loads";
            } else {
              var processMsg = response.data.message;
            }
            this.setState({
              processErrMsg: response.data.message,
              processErrShow: 1,
              transactionId: response.data.transactionId,
              statusCode: statusCode,
              loadshow: "show-n",
              overly: "show-n",
              overlayBlock: "show-n",
            });
          } else {
            this.checkTransactionStatus(response.data.transactionId);
            // this.setState({
            //     basicTitle : response.data.message,
            //     basicType : "success",
            //     show : true,
            //     loadshow:'show-n',
            //     overly:'show-n',
            //     showSlideBlockUpload:"",
            // });
          }
          //window.location.reload();
        })
        .catch(function (error) {
          var errDetails = {
            url: "/dispatch/generateLoad",
            screen: "Summary Dashboard",
            error,
          };
          redirectURL.post("/master/logErrorsForApiCalls", errDetails);
        });
    } else {
      this.setState({
        basicTitle: "Please upload the file",
        basicType: "warning",
        show: true,
      });
    }
  }

  checkTransactionStatus = (transactionId) => {
    var query = {
      transaction_id: transactionId,
    };

    redirectURL
      .post("/dispatch/checkTransactionStatus", query)
      .then((response) => {
        // console.log(response,"responseFromcheckTransactionStatus")
        var statusCode = response.data.status;
        if (statusCode == 8) {
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            droppedList: response.data.droppedList,
            statusCode: statusCode,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        } else if (statusCode > 3 && statusCode != 8) {
          this.setState({
            processErrMsg: response.data.message,
            processErrShow: 1,
            transactionId: response.data.transactionId,
            statusCode: statusCode,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        } else if (statusCode == 0) {
          var checkCount = this.state.checkCount;
          var updatedCnt = parseInt(checkCount) + 1;
          this.setState({
            checkCount: updatedCnt,
          });
          // console.log(updatedCnt,"checkCount")
          if (updatedCnt < 3) {
            this.chkStatus(response.data.transactionId);
          } else {
            this.setState({
              basicTitle: "File is processing",
              basicType: "success",
              show: true,
              loadshow: "show-n",
              overly: "show-n",
              overlayBlock: "show-n",
              showSlideBlockUpload: "",
              loadFile: "",
            });
            $("#uploadFile").val("");
            $("#notify_email").val("");
          }
        } else {
          this.setState({
            basicTitle: response.data.message,
            basicType: "success",
            show: true,
            loadshow: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
            showSlideBlockUpload: "",
          });
          this.getDispatchPlanData();
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/dispatch/checkTransactionStatus",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  chkStatus = (transactionId) => {
    this.checkTransactionStatus(transactionId);
  };

  viewPlanDetails = async (propdata) => {
    // console.log('hit')
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on View Details Button in the sub aggrid",
      event: "Navigated to summary dashboard page",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    if (
      propdata.data.data_processed == 1 ||
      propdata.data.data_processed == 2 ||
      propdata.data.data_processed == 3
    ) {
      if (propdata.colDef.field == "transaction_details") {
        let data_type = this.state.data_type;
        let location = this.state.location.value;
        let qry = {
          dispatch_planning_type: data_type,
          plant_location_code: location,
        };
        localStorage.setItem("qry", JSON.stringify(qry));

        var data = propdata.data;
        let basestring = data.transaction_id;
        let encryptedstring = window.btoa(basestring);
        // window.location.href = '/viewdispatchplandetails?'+encryptedstring;
        var ownerMails = [
          //   "nanda@enmovil.in",
          //   "ravi@enmovil.in",
          //   "nanda@enmovil.ai",
          //   "ravi@enmovil.ai",
          //   "venkat.moganty@enmovil.in",
          "venkatesh.jalla@enmovil.in",
        ];
        var email = localStorage.getItem("email");
        if (ownerMails.includes(email)) {
          window.open(
            window.location.origin + "/dispatchplandetails?" + encryptedstring,
            "_blank"
          );
        } else {
          window.open(
            window.location.origin +
              "/viewdispatchplandetails?" +
              encryptedstring,
            "_blank"
          );
        }
      }
    }

    if (propdata.colDef.field == "order_details") {
      var data = propdata.data;
      // console.log("data ", data)
      var order_id = data.order_id;
      var tool_config = data.tool_config;
      this.setState({
        tool_config: tool_config,
      });
      var params = {
        order_id: order_id,
        // sla:data.days_of_runs,
        location_code: data.location_code,
        order_date: moment.parseZone(data.order_date).format("DD-MM-YYYY"),
      };

      redirectURL
        .post("dispatch/getTransactionInputDetails", params)
        .then(async (response) => {
          var inputDetails = response.data;
          this.setState({
            inputDetails: inputDetails,
            showSlideBlockUpload: "slide45",
            overlayBlock: "show-m",
          });
        })
        .catch(function (error) {
          var errDetails = {
            url: "dispatch/getTransactionInputDetails",
            screen: "Summary Dashboard",
            error,
          };
          redirectURL.post("/master/logErrorsForApiCalls", errDetails);
        });
    }

    if (
      propdata.colDef.field == "plan_followed" &&
      propdata.data.is_plan_follow != 1
    ) {
      var data = propdata.data;
      var order_id = data.order_id;
      var tool_config = data.tool_config;
      this.setState({
        tool_config: tool_config,
      });

      var cnf = window.confirm("Are you sure you want to proceed?");
      if (cnf === true) {
        var params = {
          order_id: order_id,
          // sla:data.days_of_runs,
          transaction_id: data.transaction_id,
          location_code: data.location_code,
          order_date: moment.parseZone(data.order_date).format("DD-MM-YYYY"),
          user: localStorage.getItem("username"),
        };

        redirectURL
          .post("dispatch/setPlanFollowed", params)
          .then(async (response) => {
            redirectURL
              .post("dispatch/addApprovedPlanDetails", params)
              .then(async (response) => {});
            await this.getDispatchPlanData();
          })
          .catch(function (error) {
            var errDetails = {
              url: "dispatch/setPlanFollowed",
              screen: "Summary Dashboard",
              error,
            };
            redirectURL.post("/master/logErrorsForApiCalls", errDetails);
          });
      }
    }
  };

  viewOrderDetails = async (propdata) => {
    try {
      var data = propdata.data;
      // console.log("data ", data)
      var order_id = data.order_id;
      var tool_config = data.tool_config;
      this.setState({
        tool_config: tool_config,
      });
      var params = {
        order_id: order_id,
        // sla:data.days_of_runs,
        location_code: data.location_code,
        order_date: moment.parseZone(data.order_date).format("DD-MM-YYYY"),
      };

      let logParams = {
        location_code: this.state.location.value,
        location_name: this.state.location.label,
        user_name: localStorage.getItem("username"),
        useremail: localStorage.getItem("email"),
        client: localStorage.getItem("client"),
        screen: "Summary Dashboard",
        activity: "clicked on Drop Details View Button",
        event: "Slider Opened with Aggrid",
        data_type: this.state.data_type,
      };

      redirectURL.post("/master/loguserUpdatesorChanges", logParams);

      redirectURL
        .post("dispatch/getTransactionInputDetails", params)
        .then(async (response) => {
          var inputDetails = response.data;
          this.setState({
            inputDetails: inputDetails,
            showSlideBlockUpload: "slide45",
            overlayBlock: "show-m",
          });
        })
        .catch(function (error) {
          var errDetails = {
            url: "dispatch/getTransactionInputDetails",
            screen: "Summary Dashboard",
            error,
          };
          redirectURL.post("/master/logErrorsForApiCalls", errDetails);
        });
    } catch (error) {}
  };

  planFollowed = async (propdata) => {
    try {
      var data = propdata.data;
      // console.log("data ", data)
      // var order_id = data.order_id;
      // var tool_config = data.tool_config;
      // this.setState({
      //     tool_config : tool_config
      // })
      // var params = {
      //     order_id : order_id,
      //     // sla:data.days_of_runs,
      //     location_code:data.location_code,
      //     order_date:moment.parseZone(data.order_date).format("DD-MM-YYYY")
      // }
      // redirectURL.post('dispatch/getTransactionInputDetails',params)
      // .then(async response =>{
      //     var inputDetails = response.data;
      //     this.setState({
      //         inputDetails:inputDetails,
      //         showSlideBlockUpload:"slide45",
      //         overlayBlock:"show-m"
      //     })
      // })
    } catch (error) {}
  };

  locationChanged = async (location) => {
    var { boundLocations } = this.state;
    this.setState({
      location: location,
    });
    await getToolConfiguration({ plant_code: location.value });
  };

  viewHistoryDetails = async (row) => {
    // console.log(row,"row")
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on Plan history View Button",
      event: "Aggrid opended below the row",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    var plannedcolsDefs1 = [
      {
        headerName: "Plan Id",
        field: "transaction_id",
        width: 180,
        filter: true,
        resizable: true,
        editable: false,
      },
      // {
      //   headerName: "SLA",
      //   field: "days_of_runs",
      //   width: 150,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   valueGetter: function (params) {
      //     try {
      //       if (
      //         params.data.days_of_runs !== "" &&
      //         params.data.days_of_runs !== undefined
      //       ) {
      //         if (parseInt(params.data.days_of_runs) === 1) {
      //           return "Single Day";
      //         } else {
      //           return "Multiple Days";
      //         }
      //       } else {
      //         return "";
      //       }
      //     } catch (error) {}
      //   },
      // },
      {
        headerName: "Generated On",
        field: "createDate",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          if (params.data.createdon != "") {
            return getHyphenDDMMMYYYYHHMM(params.data.createDate);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Status",
        field: "data_processed",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          let givenDateTime = moment
            .parseZone(params.data.createDate)
            .format("DD-MM-YYYY HH:mm");
          let currentDateTime = moment
            .parseZone(new Date())
            .format("DD-MM-YYYY HH:mm");

          givenDateTime = moment(givenDateTime, "DD-MM-YYYY HH:mm");
          currentDateTime = moment(currentDateTime, "DD-MM-YYYY HH:mm");
          const differenceInMinutes = currentDateTime.diff(
            givenDateTime,
            "minutes"
          );

          const targetTimeDifferenceInMinutes = 35;
          let flag = true;

          if (differenceInMinutes >= targetTimeDifferenceInMinutes) {
            flag = false;
          }
          if (params.data.data_processed == 0) {
            if (flag) {
              return "Processing";
            } else {
              return "Please Contact Admin";
            }
          } else if (params.data.data_processed == 1) {
            return "Completed";
          } else if (params.data.data_processed == 2) {
            return "SOLVER_RECO_PROCESSED";
          } else if (params.data.data_processed == 3) {
            return "SOLVER_SOB_DONE";
          } else if (params.data.data_processed == 4) {
            return "SOLVER_INVALID_INPUT_DEALER_LOCATION";
          } else if (params.data.data_processed == 5) {
            return "SOLVER_INVALID_INPUT_MATERIALS";
          } else if (params.data.data_processed == 6) {
            return "SOLVER_INVALID_INPUT_DEALER_LOCATION_AND_MATERIALS";
          } else if (params.data.data_processed == 7) {
            return "SOLVER_INPUT_DATA_NOT_VALID";
          } else if (params.data.data_processed == 8) {
            return "SOLVER_FIX_DROPED_DEALERS";
          } else if (params.data.data_processed == 9) {
            return "SOLVER_VEHICLE_DETAILS_MISSING";
          } else if (params.data.data_processed == 10) {
            return "SOLVER_INPUT_FILE_DATA_ERROR";
          } else if (params.data.data_processed == 11) {
            return "SOLVER_INVALID_FILE_TEMPLATE";
          } else if (params.data.data_processed == 12) {
            return "PROCESS_TIMED_OUT";
          } else if (params.data.data_processed == 13) {
            return "SOLVER_SOB_FAIL";
          } else if (params.data.data_processed == 14) {
            return "SOLVER_RECO_FAILED";
          } else if (params.data.data_processed == 15) {
            return "SOLVER_NO_TOOL_CONFIG";
          } else if (params.data.data_processed == 16) {
            return "SOLVER_INVALID_TOOL_CONFIG";
          } else {
            return "Please Contact Admin";
          }
        },
      },
      // {
      //   headerName: "Optimization Type",
      //   field: "opti_type",
      //   width: 150,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   valueGetter: function (params) {
      //     if (params.data.opti_type == 0 || params.data.opti_type == "0") {
      //       return "Optimize by Distance";
      //     } else if (
      //       params.data.opti_type == 1 ||
      //       params.data.opti_type == "1"
      //     ) {
      //       return "Optimize by Time";
      //     }
      //   },
      // },
      {
        headerName: "Optimization Type",
        field: "opti_type",
        width: 200,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          if (
            params.data.run_with_transit == 1 ||
            params.data.run_with_transit == "1"
          ) {
            return "Transit Time With Multiple City Clubing";
          } else if (
            params.data.run_with_RDD == 1 ||
            params.data.run_with_RDD == "1"
          ) {
            return "RDD Preference";
          } else if (
            params.data.run_with_transit_cityclub == 1 ||
            params.data.run_with_transit_cityclub == "1"
          ) {
            return "Transit Time With Single City Clubing";
          }
        },
      },
      // {
      //   headerName: "No. of Trucks Planned",
      //   field: "no_of_vehicles_planned",
      //   width: 160,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      // },
      // {
      //   headerName: "Total Planned Volume (M3)",
      //   field: "planned_load_volume",
      //   width: 200,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   valueGetter: function (params) {
      //     if (params.data.planned_load_volume) {
      //       return params.data.planned_load_volume.toFixed(2);
      //     }
      //   },
      // },
      // {
      //   headerName: "Total Planned Weight (Kgs)",
      //   field: "planned_load_weight",
      //   width: 200,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   valueGetter: function (params) {
      //     if (params.data.planned_load_weight) {
      //       return params.data.planned_load_weight.toFixed(2);
      //     }
      //   },
      // },
      // {
      //   headerName: "Total Planned Distance (KM)",
      //   field: "total_plan_distance_km",
      //   width: 200,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   valueGetter: function (params) {
      //     console.log(params, "hello");
      //     if (
      //       params.data.total_plan_distance_km !== "" &&
      //       params.data.total_plan_distance_km !== undefined
      //     ) {
      //       return params.data.total_plan_distance_km.toFixed(2);
      //     }
      //   },
      // },
      // {
      //   headerName: "Total Planned Time (Mins)",
      //   field: "total_plan_time_mins",
      //   width: 200,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      // },

      // {
      //   headerName: "Non-Pallet Load (lbs)",
      //   field: "planned_load_cmt",
      //   width: 180,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   valueGetter: function (params) {
      //     try {
      //       if (
      //         params.data.planned_load_cmt !== "" &&
      //         params.data.planned_load_cmt !== undefined
      //       ) {
      //         return Math.round(params.data.planned_load_cmt);
      //       } else {
      //         return "";
      //       }
      //     } catch (error) {}
      //   },
      // },
      // {
      //   headerName: "Pallet Load (lbs)",
      //   field: "unplanned_load_cmt",
      //   width: 150,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   valueGetter: function (params) {
      //     try {
      //       if (
      //         params.data.unplanned_load_cmt !== "" &&
      //         params.data.unplanned_load_cmt !== undefined
      //       ) {
      //         return Math.round(params.data.unplanned_load_cmt);
      //       } else {
      //         return "";
      //       }
      //     } catch (error) {}
      //   },
      // },
      // {
      //   headerName: "No. of Passengers Planned",
      //   field: "planned_load_cmt",
      //   width: 150,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      // },
      // {
      //   headerName: "No. of Passengers Ignored",
      //   field: "unplanned_load_cmt",
      //   width: 150,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      // },
      // {
      //   headerName: "Remarks",
      //   field: "remarks",
      //   width: 200,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   valueGetter: function (params) {
      //     if (params.data.remarks != undefined && params.data.remarks != "") {
      //       return params.data.remarks;
      //     } else {
      //       return "N/A";
      //     }
      //   },
      // },
      {
        pinned: "left",
        headerName: "View Details",
        field: "transaction_details",
        width: 120,
        resizable: true,
        editable: false,
        // params:{buttonName:'View',iconName:'fa fa-eye',
        // onClickFunction:this.viewPlanDetails},
        cellRenderer: function (params) {
          // console.log(":params ", params.data)
          if (
            params.data.data_processed == 1 ||
            params.data.data_processed == 2 ||
            params.data.data_processed == 3
          ) {
            return <StaticButton />;
          } else {
            return <NAButton />;
          }
        },
      },
      // {
      //   headerName: "Implement Plan",
      //   field: "plan_followed",
      //   pinned: "left",
      //   width: 180,
      //   resizable: true,
      //   editable: false,
      //   cellClass: function (params) {
      //     try {
      //       if (params.data.vehicle_planned_flag === 1) {
      //         return "gHighlight";
      //       } else {
      //         return "";
      //       }
      //     } catch (error) {}
      //   },
      //   params: {
      //     buttonName: "Freeze Plan",
      //     iconName: "fa fa-eye",
      //     buttonCls: "btn btn-info",
      //     onClickFunction: this.planFollowed,
      //   },
      //   cellRenderer: function (params) {
      //     if (
      //       params.data.is_plan_follow !== undefined &&
      //       params.data.is_plan_follow === 1 &&
      //       params.data.approved_by !== undefined &&
      //       params.data.approved_by != ""
      //     ) {
      //       return "Selected Plan";
      //     } else if (
      //       params.data.is_plan_follow !== undefined &&
      //       params.data.is_plan_follow == 0
      //     ) {
      //       return "Plan Implemented";
      //     } else {
      //       return <PlanFollowedButton />;
      //     }
      //   },
      // },
      {
        headerName: "Plan Run By",
        field: "useremail",
        width: 170,
        filter: true,
        resizable: true,
        editable: false,
      },
      // {
      //   headerName: "Plan Approved By",
      //   field: "approved_by",
      //   width: 170,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   valueGetter: function (params) {
      //     if (
      //       params.data.approved_by != undefined &&
      //       params.data.approved_by != ""
      //     ) {
      //       return params.data.approved_by;
      //     } else {
      //       return "NA";
      //     }
      //   },
      // },
      // {
      //   headerName: "Approved Time",
      //   field: "approved_time",
      //   width: 170,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   valueGetter: function (params) {
      //     if (
      //       params.data.approved_time != undefined &&
      //       params.data.approved_time != ""
      //     ) {
      //       return moment
      //         .parseZone(params.data.approved_time)
      //         .format("DD-MM-YYYY HH:MM");
      //     } else {
      //       return "NA";
      //     }
      //   },
      // },
      // {
      //   pinned: "left",
      //   headerName: "Order Details",
      //   field: "order_details",
      //   width: 120,
      //   resizable: true,
      //   editable: false,
      //   cellClass: function (params) {
      //     try {
      //       if (params.data.vehicle_planned_flag === 1) {
      //         return "gHighlight";
      //       } else {
      //         return "";
      //       }
      //     } catch (error) {}
      //   },
      //   params: {
      //     buttonName: "View",
      //     iconName: "fa fa-eye",
      //     buttonCls: "btn btn-warning",
      //     onClickFunction: this.viewOrderDetails,
      //   },
      //   cellRenderer: function (params) {
      //     return <OrderButton />;
      //   },
      // },
    ];
    var { generateDate } = this.state;
    await this.setState({
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: plannedcolsDefs1,
          overlayNoRowsTemplate: "No rows to show",
          onCellClicked: this.viewPlanDetails,
          height: 200,
        },
        getDetailRowData: async function (param) {
          // console.log('hit')
          param.successCallback([]);
          await redirectURL
            .post("/dispatch/getPlanRunHistory", {
              order_id: row.data.order_id,
              generateDate,
            })
            .then(async (response) => {
              // console.log(response.data)
              param.successCallback(response.data);
            })
            .catch(function (error) {
              console.log(error);

              var errDetails = {
                url: "/dispatch/getPlanRunHistory",
                error,
                screen: "Summary Dashboard",
              };
              redirectURL.post("/master/logErrorsForApiCalls", errDetails);
            });
        },
        masterDetail: true,
      },
    });
    if (row.colDef.field == "run_history") {
      row.node.setExpanded(!row.node.expanded);
    } else {
      row.node.setExpanded(false);
    }
  };
  onClickRunNew = async (row) => {
    var data = { data: row.data };
    // var row = this.state.clickedRow;
    await redirectURL
      .post("/dispatch/getDispatchPlanDataForOrder", {
        order_id: row.data.order_id,
      })
      .then(async (response) => {
        let transData = response.data;
        // console.log("first h", transData);
        if (transData[0].vehicle_assigned == 1) {
          // window.confirm("Vehicle Already Assigned For This Order");
          this.setState({
            basicTitle: "Vehicle Already Assigned For This Order..!",
            basicType: "info",
            show: true,
          });
        } else {
          this.setState({ modalForRunNew: true, clickedRow: data });
        }
      });
  };

  planNewDispatch = () => {
    var row = this.state.clickedRow;
    redirectURL
      .post("/dispatch/getDispatchPlanDataForOrder", {
        order_id: row.data.order_id,
      })
      .then(async (response) => {
        let transData = response.data;
        if (transData[0].vehicle_assigned == 1) {
          // window.confirm("Vehicle Already Assigned For This Order");
          this.setState({
            basicTitle: "Vehicle Already Assigned For This Order",
            basicType: "info",
            show: true,
          });
        } else {
          // var cnf = window.confirm("Are you sure you want to proceed?");
          if (true) {
            let logParams = {
              location_code: this.state.location.value,
              location_name: this.state.location.label,
              user_name: localStorage.getItem("username"),
              useremail: localStorage.getItem("email"),
              client: localStorage.getItem("client"),
              screen: "Summary Dashboard",
              activity: "clicked on Run New Button",
              event: "Navigated to Run New Plan Page",
              data_type: this.state.data_type,
            };

            redirectURL.post("/master/loguserUpdatesorChanges", logParams);
            var planRowData = row.data;
            // var sla = this.state.sla.value;
            // console.log("planRowData ", planRowData)
            let basestring = planRowData.order_id;
            let encryptedstring = window.btoa(basestring);
            // window.location.href="/plannewdispatch?"+encryptedstring;

            var selectDate = moment
              .parseZone(planRowData.order_date)
              .format("DD-MM-YYYY");
            let basestringDat = selectDate;
            let encryptedstringDate = window.btoa(basestringDat);
            window.location.href = "/plannewdispatch?" + encryptedstring;
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/dispatch/getDispatchPlanDataForOrder",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };
  executePlanNow(event) {
    event.preventDefault();
    var planRowData = this.state.planRowData;
    var sla = this.state.sla.value;
    // console.log("planRowData ", planRowData)
    let basestring = planRowData.order_id;
    let encryptedstring = window.btoa(basestring);
    // window.location.href="/plannewdispatch?"+encryptedstring;

    var selectDate = moment
      .parseZone(planRowData.order_date)
      .format("DD-MM-YYYY");
    let basestringDat = selectDate;
    let encryptedstringDate = window.btoa(basestringDat);
    window.location.href = "/plannewdispatch?" + encryptedstring + "&" + sla;
  }

  handelPickupDropModelCancel = () => {
    this.setState((prevState) => ({
      openSLA: false,
      addressLng: "",
      addressLat: "",
      address_name: "",
      location_name: "",
      location_type: "",
      location_code: "",
      pickup_window_time: ["00:00", "23:59"],
      drop_window_time: ["00:00", "23:59"],
    }));
  };

  slaChanged(sla) {
    this.setState({ sla }, () => {
      if (this.state.sla.value === 2) {
        var selectDate = this.state.planRowData.order_date;
        // console.log("selectDate ", selectDate)
        // var c =moment(selectDate,"DD-MM-YYYY").format("YYYY-MM-DD")
        var c = moment.parseZone(selectDate).format("DD-MM-YYYY");
        var d = moment
          .parseZone(selectDate)
          .add(1, "days")
          .format("DD-MM-YYYY");
        $("#fDate").val(c);
        $("#tDate").val(d);
      } else {
        var selectDate = this.state.planRowData.order_date;
        var c = moment.parseZone(selectDate).format("DD-MM-YYYY");
        $("#fDate").val(c);
        $("#tDate").val(c);
      }
    });
  }

  getPendingTransactions = (latestData) => {
    let data;
    if (latestData.length > 0) {
      data = latestData;
    } else {
      data = this.state.latestData;
    }

    var locValue = this.state.location.value;
    // console.log('first late4',data,locValue)
    var transactions = data.filter(
      (item) => item.location_code === locValue || "All" === locValue
    );
    var allTransactionIds = [];

    redirectURL
      .post("/dispatch/getPlanRunHistory", {
        order_id: transactions[0].order_id,
      })
      .then(async (response) => {
        let transData = response.data;

        transData.map((itm) => {
          if (itm.data_processed !== 1 && itm.data_processed !== 17) {
            let givenDateTime = moment
              .parseZone(itm.createDate)
              .format("DD-MM-YYYY HH:mm");
            let currentDateTime = moment
              .parseZone(new Date())
              .format("DD-MM-YYYY HH:mm");

            givenDateTime = moment(givenDateTime, "DD-MM-YYYY HH:mm");
            currentDateTime = moment(currentDateTime, "DD-MM-YYYY HH:mm");
            const differenceInMinutes = currentDateTime.diff(
              givenDateTime,
              "minutes"
            );

            const targetTimeDifferenceInMinutes = 25;
            let flag = true;

            if (differenceInMinutes >= targetTimeDifferenceInMinutes) {
              flag = false;
            }

            if (differenceInMinutes <= 8) {
              if (localStorage.getItem(itm.transaction_id) != 0 && flag) {
                allTransactionIds.push({
                  id: itm.transaction_id,
                  duration: (8 - differenceInMinutes) * 60,
                });
              }
            } else {
              if (localStorage.getItem(itm.transaction_id) != 0 && flag) {
                allTransactionIds.push({
                  id: itm.transaction_id,
                  duration: 8 * 60,
                });
              }
            }

            if (localStorage.getItem(itm.transaction_id) === 0) {
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Please Contact Admin Time Elapsed",
              });
              var url = window.location.host;
              var environment = "";
              if (url === "20.197.34.195:3000") {
                environment = "DEV";
              } else if (url === "164.52.218.255:3009") {
                environment = "QA";
              } else if (url === "nestle.enmovil.net") {
                environment = "UAT";
              } else if (url === "nestle.optirun.in") {
                environment = "PROD";
              } else {
                environment = "Local";
              }
              var details = {
                env: environment,
                tran_id: itm.transaction_id,
                plant_code: this.state.location.value,
                email: localStorage.getItem("email"),
              };

              redirectURL.post("/master/triggerEmailOnTimeOut", details);
              localStorage.removeItem(itm.transaction_id);
            }
          } else {
            localStorage.removeItem(itm.transaction_id);
          }
          return true;
        });
        if (allTransactionIds.length === 0) {
          clearInterval(this.intervalId);
        }
        // console.log('first upon',allTransactionIds)
        await this.setState({ allTransactionIds: allTransactionIds });
      })
      .catch(function (error) {
        var errDetails = {
          url: "/dispatch/getPlanRunHistory",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // January is 0
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  onChangeRegion = (region) => {
    this.setState(
      {
        region: region,
        country: { value: "All", label: "All" },
        zone: { value: "All", label: "All" },
        state: { value: "All", label: "All" },
        location: { value: "All", label: "All" },
      },
      () => {
        this.fetchOptionsForRegions(region.value);
      }
    );
  };

  fetchOptionsForCountry = (value) => {
    // console.log(value, "country first");

    let region = { value: "All", label: "All" };
    let regionOptions = [{ value: "All", label: "All" }];
    let countryOptions = [{ value: "All", label: "All" }];
    let branchOptions = [{ value: "All", label: "All" }];
    let dcOptions = [{ value: "All", label: "All" }];
    let country = { value: "All", label: "All" };

    let regionSet = new Set();
    let countrySet = new Set();
    let branchSet = new Set();
    let dcSet = new Set();
    let location_access = JSON.parse(localStorage.getItem("location_access"));
    var boundLocations = this.state.boundLocations;
    if (location_access !== null) {
      boundLocations = this.state.boundLocations.filter((item) =>
        location_access.includes(item.location_code)
      );
    }
    for (let i of boundLocations) {
      if (value !== "All") {
        if (i.country && i.country === value) {
          region = {
            value: i.region.toUpperCase(),
            label: i.region.toUpperCase(),
          };
          country = {
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          };

          if (!regionSet.has(i.region)) {
            regionOptions.push({
              value: i.region,
              label: i.region,
            });
            regionSet.add(i.region);
          }

          if (!branchSet.has(i.location_zone.toUpperCase())) {
            branchOptions.push({
              value: i.location_zone,
              label: i.location_zone,
            });
            branchSet.add(i.location_zone.toUpperCase());
          }

          if (!dcSet.has(i.location_code)) {
            dcOptions.push({
              value: i.location_code,
              label: i.location_name,
            });
            dcSet.add(i.location_code);
          }
        }
        if (!countrySet.has(i.country)) {
          countryOptions.push({
            value: i.country,
            label: i.country,
          });
          countrySet.add(i.country);
        }
      } else {
        if (!regionSet.has(i.region)) {
          regionOptions.push({
            value: i.region,
            label: i.region,
          });
          regionSet.add(i.region);
        }
        if (!countrySet.has(i.country.toUpperCase())) {
          countryOptions.push({
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          });
          countrySet.add(i.country.toUpperCase());
        }
        if (!branchSet.has(i.location_zone.toUpperCase())) {
          branchOptions.push({
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          });
          branchSet.add(i.location_zone.toUpperCase());
        }
        if (!dcSet.has(i.location_code)) {
          dcOptions.push({
            value: i.location_code,
            label: i.location_name,
          });
          dcSet.add(i.location_code);
        }
      }
    }

    this.setState({
      region: region,
      country: country,
      regions: regionOptions,
      countries: countryOptions,
      locationZones: branchOptions,
      locationNames: dcOptions,
    });
  };

  locationCountryChange = (country) => {
    this.setState(
      {
        country: country,
        // zone: { value: "All", label: "All" },
        // state: { value: "All", label: "All" },
        // location: { value: "All", label: "All" },
      }
      // () => {
      //   this.fetchOptionsForCountry(country.value);
      // }
    );
  };

  locationZoneChange = (zone) => {
    this.setState(
      {
        zone: zone,
        // state: { value: "All", label: "All" },
        // location: { value: "All", label: "All" },
      }
      // () => {
      //   this.fetchOptionsForBranch(zone.value);
      // }
    );
  };

  boundTypeLocations = async (param) => {
    var location_access = JSON.parse(localStorage.getItem("location_access"));
    var locationNames = [{ label: "All", value: "All" }];
    var countries = [{ label: "All", value: "All" }];
    var locationZones = [{ label: "All", value: "All" }];
    var regions = [{ label: "All", value: "All" }];
    var countriesSet = new Set();
    var locationZoneSet = new Set();
    var regionSet = new Set();
    var locationNameSet = new Set();
    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status === "success") {
          var boundLocations = response.data.boundLocations;
          var pickupLocationCodes = {};
          boundLocations.map((item) => {
            pickupLocationCodes[item.location_code] = item.location_name;
            return true;
          });
          this.setState({
            boundLocations: response.data.boundLocations,
            pickupLocationCodes,
          });
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                if (location_access.includes(item.location_code)) {
                  locationNames.push({
                    value: item.location_code,
                    label: item.location_name,
                  });

                  if (
                    item.country &&
                    item.country !== null &&
                    !countriesSet.has(item.country)
                  ) {
                    // console.log("first loop", item.country);
                    countries.push({
                      value: item.country.toUpperCase(),
                      label: item.country.toUpperCase(),
                    });
                    countriesSet.add(item.country.toUpperCase());
                  }
                  if (
                    item.region &&
                    item.region !== null &&
                    !regionSet.has(item.region)
                  ) {
                    regions.push({
                      value: item.region.toUpperCase(),
                      label: item.region.toUpperCase(),
                    });
                    regionSet.add(item.region.toUpperCase());
                  }
                  if (
                    item.location_zone &&
                    item.location_zone !== null &&
                    !locationZoneSet.has(item.location_zone)
                  ) {
                    locationZones.push({
                      value: item.location_zone.toUpperCase(),
                      label: item.location_zone.toUpperCase(),
                    });
                    locationZoneSet.add(item.location_zone.toUpperCase());
                  }
                }
              } else {
                if (
                  item.country &&
                  item.country !== null &&
                  !countriesSet.has(item.country)
                ) {
                  countries.push({
                    value: item.country.toUpperCase(),
                    label: item.country.toUpperCase(),
                  });
                  countriesSet.add(item.country.toUpperCase());
                }
                if (
                  item.region &&
                  item.region !== null &&
                  !regionSet.has(item.region)
                ) {
                  regions.push({
                    value: item.region.toUpperCase(),
                    label: item.region.toUpperCase(),
                  });
                  regionSet.add(item.region.toUpperCase());
                }
                if (
                  item.location_zone &&
                  item.location_zone !== null &&
                  !locationZoneSet.has(item.location_zone)
                ) {
                  locationZones.push({
                    value: item.location_zone.toUpperCase(),
                    label: item.location_zone.toUpperCase(),
                  });
                  locationZoneSet.add(item.location_zone.toUpperCase());
                }
                if (
                  item.location_code &&
                  item.location_code !== null &&
                  item.location_name !== null &&
                  !locationNameSet.has(item.location_code)
                ) {
                  locationNames.push({
                    value: item.location_code.toUpperCase(),
                    label: item.location_name.toUpperCase(),
                  });
                  locationNameSet.add(item.location_code.toUpperCase());
                }
              }
              return true;
            });

            if (regions.length > 0) {
              await this.setState({
                regions: regions,
                region: regions[0],
              });
            } else {
              await this.setState({
                regions: [{ label: "Please Add Data", value: "no_data" }],
                region: [{ label: "Please Add Data", value: "no_data" }],
              });
            }

            if (countries.length > 0) {
              await this.setState({
                countries: countries,
                country: countries[0],
              });
            } else {
              await this.setState({
                countries: [{ label: "Please Add Data", value: "no_data" }],
                country: [{ label: "Please Add Data", value: "no_data" }],
              });
            }

            if (locationZones.length > 0) {
              await this.setState({
                locationZones: locationZones,
                zone: locationZones[0],
              });
            } else {
              await this.setState({
                locationZones: [{ label: "Please Add Data", value: "no_data" }],
                zone: [{ label: "Please Add Data", value: "no_data" }],
              });
            }
            // console.log("first locationNames", locationNames);
            if (locationNames.length > 0) {
              await this.setState({
                locationNames: locationNames,
                location: locationNames[0],
              });
            } else {
              await this.setState({
                locationNames: [{ label: "Please Add Data", value: "no_data" }],
                location: [{ label: "Please Add Data", value: "no_data" }],
              });
            }
          }
        }
      });
  };

  fetchOptionsForLocation = (value) => {
    // console.log(value, "location first");

    var country = { value: "All", label: "All" };
    var region = { value: "All", label: "All" };
    var branch = { value: "All", label: "All" };
    var dcOption = { value: "All", label: "All" };
    var regionOptions = [{ value: "All", label: "All" }];
    var countryOptions = [{ value: "All", label: "All" }];
    var branchOptions = [{ value: "All", label: "All" }];
    var dcOptions = [{ value: "All", label: "All" }];

    var regionSet = new Set();
    var countrySet = new Set();
    var branchSet = new Set();
    var dcSet = new Set();
    var location_access = JSON.parse(localStorage.getItem("location_access"));
    var boundLocations = this.state.boundLocations;
    if (location_access !== null) {
      boundLocations = this.state.boundLocations.filter((item) =>
        location_access.includes(item.location_code)
      );
    }
    for (var i of boundLocations) {
      if (value !== "All") {
        if (
          i.location_code &&
          i.location_code.toUpperCase() === value.toUpperCase()
        ) {
          branch = {
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          };
          region = { value: i.region, label: i.region };
          country = {
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          };
          dcOption = { value: value, label: i.location_name };
        }
        if (!regionSet.has(i.region)) {
          regionOptions.push({
            value: i.region,
            label: i.region,
          });
          regionSet.add(i.region);
        }
        if (!countrySet.has(i.country.toUpperCase())) {
          countryOptions.push({
            value: i.country,
            label: i.country,
          });
          countrySet.add(i.country.toUpperCase());
        }
        if (!branchSet.has(i.location_zone.toUpperCase())) {
          branchOptions.push({
            value: i.location_zone,
            label: i.location_zone,
          });
          branchSet.add(i.location_zone.toUpperCase());
        }
        if (!dcSet.has(i.location_code)) {
          dcOptions.push({
            value: i.location_code,
            label: i.location_name,
          });
          dcSet.add(i.location_code);
        }
      } else {
        console.log("first");
        if (!regionSet.has(i.region)) {
          regionOptions.push({
            value: i.region,
            label: i.region,
          });
          regionSet.add(i.region);
        }
        if (!countrySet.has(i.country.toUpperCase())) {
          countryOptions.push({
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          });
          countrySet.add(i.country.toUpperCase());
        }
        if (!branchSet.has(i.location_zone.toUpperCase())) {
          branchOptions.push({
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          });
          branchSet.add(i.location_zone.toUpperCase());
        }
        if (!dcSet.has(i.location_code)) {
          dcOptions.push({
            value: i.location_code,
            label: i.location_name,
          });
          dcSet.add(i.location_code);
        }
      }
    }
    this.setState({
      country: country,
      region: region,
      zone: branch,
      location: dcOption,
      regions: regionOptions,
      countries: countryOptions,
      locationZones: branchOptions,
      locationNames: dcOptions,
    });
  };

  fetchOptionsForBranch = (value) => {
    // console.log(value, "branch first");

    var country = { value: "All", label: "All" };
    var region = { value: "All", label: "All" };
    var branch = { value: "All", label: "All" };
    var regionOptions = [{ value: "All", label: "All" }];
    var countryOptions = [{ value: "All", label: "All" }];
    var branchOptions = [{ value: "All", label: "All" }];
    var dcOptions = [{ value: "All", label: "All" }];

    var regionSet = new Set();
    var branchSet = new Set();
    var countrySet = new Set();
    var dcSet = new Set();
    var location_access = JSON.parse(localStorage.getItem("location_access"));
    var boundLocations = this.state.boundLocations;
    if (location_access !== null) {
      boundLocations = this.state.boundLocations.filter((item) =>
        location_access.includes(item.location_code)
      );
    }
    for (let i of boundLocations) {
      if (value !== "All") {
        if (
          i.location_zone.toUpperCase() &&
          i.location_zone.toUpperCase() === value.toUpperCase()
        ) {
          branch = {
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          };
          region = { value: i.region, label: i.region };
          country = {
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          };
          if (!dcSet.has(i.location_code)) {
            dcOptions.push({
              value: i.location_code,
              label: i.location_name,
            });
            dcSet.add(i.location_code);
          }
        }
        if (!regionSet.has(i.region)) {
          regionOptions.push({
            value: i.region,
            label: i.region,
          });
          regionSet.add(i.region);
        }
        if (!branchSet.has(i.location_zone.toUpperCase())) {
          branchOptions.push({
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          });
          branchSet.add(i.location_zone.toUpperCase());
        }
        if (!countrySet.has(i.country.toUpperCase())) {
          countryOptions.push({
            value: i.country,
            label: i.country,
          });
          countrySet.add(i.country.toUpperCase());
        }
      } else {
        if (!regionSet.has(i.region)) {
          regionOptions.push({
            value: i.region,
            label: i.region,
          });
          regionSet.add(i.region);
        }
        if (!countrySet.has(i.country.toUpperCase())) {
          countryOptions.push({
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          });
          countrySet.add(i.country.toUpperCase());
        }
        if (!branchSet.has(i.location_zone.toUpperCase())) {
          branchOptions.push({
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          });
          branchSet.add(i.location_zone.toUpperCase());
        }
        if (!dcSet.has(i.location_code)) {
          dcOptions.push({
            value: i.location_code,
            label: i.location_name,
          });
          dcSet.add(i.location_code);
        }
      }
    }

    this.setState({
      region: region,
      zone: branch,
      country: country,
      regions: regionOptions,
      countries: countryOptions,
      locationZones: branchOptions,
      locationNames: dcOptions,
    });
  };

  fetchOptionsForRegions = (value) => {
    console.log(value, "regions");

    let regionOptions = [{ value: "All", label: "All" }];
    let countryOptions = [{ value: "All", label: "All" }];
    let branchOptions = [{ value: "All", label: "All" }];
    let dcOptions = [{ value: "All", label: "All" }];
    let region = { value: "All", label: "All" };

    let countrySet = new Set();
    let branchSet = new Set();
    let dcSet = new Set();
    let location_access = JSON.parse(localStorage.getItem("location_access"));
    var boundLocations = this.state.boundLocations;
    if (location_access !== null) {
      boundLocations = this.state.boundLocations.filter((item) =>
        location_access.includes(item.location_code)
      );
    }
    for (let i of boundLocations) {
      if (value !== "All") {
        if (i.region && i.region.toUpperCase() === value.toUpperCase()) {
          region = { value: value.toUpperCase(), label: value.toUpperCase() };

          if (!countrySet.has(i.country.toUpperCase())) {
            countryOptions.push({
              value: i.country,
              label: i.country,
            });
            countrySet.add(i.country.toUpperCase());
          }

          if (!branchSet.has(i.location_zone.toUpperCase())) {
            branchOptions.push({
              value: i.location_zone,
              label: i.location_zone,
            });
            branchSet.add(i.location_zone.toUpperCase());
          }

          if (!dcSet.has(i.location_code)) {
            dcOptions.push({
              value: i.location_code,
              label: i.location_name,
            });
            dcSet.add(i.location_code);
          }
        }
      } else {
        if (!countrySet.has(i.country.toUpperCase())) {
          countryOptions.push({
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          });
          countrySet.add(i.country);
        }
        if (!branchSet.has(i.location_zone.toUpperCase())) {
          branchOptions.push({
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          });
          branchSet.add(i.location_zone);
        }
        if (!dcSet.has(i.location_code)) {
          dcOptions.push({
            value: i.location_code,
            label: i.location_name,
          });
          dcSet.add(i.location_code);
        }
      }
    }

    this.setState({
      state: { value: "All", label: "All" },
      location: dcOptions[0],
      region: region,
      countries: countryOptions,
      locationZones: branchOptions,
      locationNames: dcOptions,
    });
  };
  onChangeDispatchType = (dispatchType) => {
    // var { name, value } = event.target;
    // console.log("first eve", dispatchType);
    this.setState({ dispatchType });
  };
  handelUpdateModelCancel = () => {
    this.setState({
      modalForRunNew: false,
      overlayBlock: "show-n",
    });
  };

  render() {
    var { pickupLocationCodes } = this.state;
    var inputDetailscolsDefs = [
      {
        headerName: "Pick Up Location (Source)",
        field: "pickup_location_code",
        width: "150",
      },
      {
        headerName: "Drop Location Code (Destination)",
        field: "drop_location_code",
        width: "150",
      },
      {
        headerName: "Drop Location Name (Destination)",
        field: "drop_location_name",
        width: "200",
      },
      {
        headerName: "Drop Location City",
        field: "location_city",
        width: "150",
      },
      {
        headerName: "Order Number",
        field: "order_number",
        width: "100",
      },
      {
        headerName: "Invoicing Date",
        field: "invoicing_date",
        width: "120",
      },
      {
        headerName: "Quantity",
        field: "quantity",
        width: "100",
      },
      {
        headerName: "Demand Volume (M3)",
        field: "demand_cmt",
        width: 100,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Weight (Kgs)",
        field: "weight_tons",
        width: 100,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Dealer Available From",
        field: "dealer_available_start",
        width: "110",
      },
      {
        headerName: "Dealer Available To",
        field: "dealer_available_end",
        width: "120",
      },
      {
        headerName: "Delivery Date",
        field: "delivery_date",
        width: "120",
      },
      {
        headerName: "Product Code",
        field: "product_code",
        width: "90",
      },
      // {
      //     headerName: "Warehouse Name",
      //     field: "pickup_location_code",
      //     width:130,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      // },
      // {
      //     headerName: "Destination City",
      //     field: "drop_location_city",
      //     width:130,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      // },
      // {
      //     headerName: "Destination State",
      //     field: "drop_location_state",
      //     width:200,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      // },
      // {
      //     headerName: "Sales Order No",
      //     field: "so_number",
      //     width:200,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      // },
      // {
      //     headerName: "No. of Tires",
      //     field: "no_of_tires",
      //     width:200,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      // },
      // {
      //     headerName: "Order Date",
      //     field: "order_date",
      //     width:200,
      //     filter: true,
      //     resizable: true,
      //     editable:false,
      //     valueGetter:function(params){
      //         try {
      //             if(params.data.order_date !== "" && params.data.order_date !== undefined)
      //             {
      //                 return getHyphenDDMMMYYYY(params.data.order_date);
      //             }
      //             else{
      //                 return "";
      //             }
      //         } catch (error) {

      //         }
      //     }
      // }
    ];
    var columnwithDefs = [
      {
        headerName: "Dispatch Type",
        field: "dispatch_type",
        width: 100,
      },
      {
        headerName: "Location Name",
        field: "location_name",
        width: 130,
      },
      {
        headerName: "Order Id",
        field: "order_id",
        width: 160,
        filter: true,
        resizable: true,
        // cellClass:function(params){
        //     try {
        //         if(params.data.vehicle_planned_flag === 1)
        //         {
        //             return "gHighlight";
        //         }
        //         else{
        //             return "";
        //         }
        //     } catch (error) {

        //     }
        // },
        editable: false,
      },
      // {
      //     headerName: "Order Date",
      //     field: "order_date",
      //     width:200,
      //     filter: true,
      //     resizable: true,
      //     editable:false,
      //     cellClass:function(params){
      //         try {
      //             if(params.data.vehicle_planned_flag === 1)
      //             {
      //                 return "gHighlight";
      //             }
      //             else{
      //                 return "";
      //             }
      //         } catch (error) {

      //         }
      //     },
      //     valueGetter:function(params){
      //         if(params.data.order_date != ''){
      //             return getHyphenDDMMMYYYYHHMM(params.data.order_date);
      //         }
      //         else{
      //             return "";
      //         }
      //     },

      // },
      {
        headerName: "No Of Runs",
        field: "no_of_runs",
        width: 20,
        filter: true,
        resizable: true,
        editable: false,
        cellClass: "text-center",
        // cellClass:function(params){
        //     try {
        //         if(params.data.vehicle_planned_flag === 1)
        //         {
        //             return "gHighlight";
        //         }
        //         else{
        //             return "";
        //         }
        //     } catch (error) {

        //     }
        // },
      },
      {
        headerName: "Created On",
        field: "createDate",
        width: 130,
        filter: true,
        resizable: true,
        editable: false,
        // cellClass:function(params){
        //     try {
        //         if(params.data.vehicle_planned_flag === 1)
        //         {
        //             return "gHighlight";
        //         }
        //         else{
        //             return "";
        //         }
        //     } catch (error) {

        //     }
        // },
        valueGetter: function (params) {
          if (params.data.createDate != "") {
            return getHyphenDDMMMYYYYHHMM(params.data.createDate);
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Plan Run By",
        field: "useremail",
        width: 170,
        filter: true,
        resizable: true,
        editable: false,
        // valueGetter: (params) => {
        //   console.log("first", params.data);
        // },
      },
      // {
      //     headerName: "Plan Approved By",
      //     field: "approved_by",
      //     width:170,
      //     filter: true,
      //     resizable: true,
      //     editable:false,
      //     valueGetter:function(params){
      //         if(params.data.approved_by != undefined && params.data.approved_by != ''){
      //             return params.data.approved_by;
      //         }
      //         else{
      //             return "NA";
      //         }
      //     },
      // },
      // {
      //     headerName: "Approved Time",
      //     field: "approved_time",
      //     width:170,
      //     filter: true,
      //     resizable: true,
      //     editable:false,
      //     valueGetter:function(params){
      //         if(params.data.approved_time != undefined && params.data.approved_time != ''){
      //             return moment.parseZone(params.data.approved_time).format("DD-MM-YYYY HH:MM")
      //         }
      //         else{
      //             return "NA";
      //         }
      //     },
      // },
      // {
      //     headerName: "",
      //     field: "vehicle_planned_flag",
      //     width:70,
      //     filter: true,
      //     resizable: true,
      //     editable:false,
      //     // cellClass:function(params){
      //     //     try {
      //     //         if(params.data.vehicle_planned_flag === 1)
      //     //         {
      //     //             return "gHighlight";
      //     //         }
      //     //         else{
      //     //             return "";
      //     //         }
      //     //     } catch (error) {

      //     //     }
      //     // },
      //     cellRenderer:"FlagIcon",
      // },
      {
        pinned: "left",
        headerName: "Plan History",
        field: "run_history",
        width: 100,
        resizable: true,
        editable: false,
        cellClass: function (params) {
          try {
            if (params.data.vehicle_assigned === 1) {
              return "light-bg";
            } else {
              return "";
            }
          } catch (error) {}
        },
        params: {
          buttonName: "View",
          iconName: "fa fa-eye",
          buttonCls: "btn btn-danger",
          onClickFunction: this.viewHistoryDetails,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      // {
      //     pinned:"left",
      //     headerName : "Order Details",
      //     field:"order_id",
      //     width:150,
      //     resizable : true,
      //     editable : false,
      //     cellClass:function(params){
      //         try {
      //             if(params.data.vehicle_planned_flag === 1)
      //             {
      //                 return "gHighlight";
      //             }
      //             else{
      //                 return "";
      //             }
      //         } catch (error) {

      //         }
      //     },
      //     params:{buttonName:'View',iconName:'fa fa-eye',buttonCls:'btn btn-info',
      //     onClickFunction:this.viewOrderDetails},
      //     cellRendererSelector:function(params){
      //         var rendComponent = {
      //             component: "GridButton"
      //         };
      //         return rendComponent
      //     },
      // },
      {
        pinned: "left",
        headerName: "Drop Details",
        field: "order_id",
        width: 100,
        resizable: true,
        editable: false,
        params: {
          buttonName: "View",
          iconName: "fa fa-eye",
          buttonCls: "btn btn-info",
          onClickFunction: this.viewOrderDetails,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
        cellClass: function (params) {
          try {
            if (params.data.vehicle_assigned == 1) {
              return "light-bg";
            } else {
              return "";
            }
          } catch (error) {}
        },
      },
      {
        pinned: "left",
        headerName: "Generate Plan",
        field: "pla_new_dispatch",
        width: 150,
        resizable: true,
        editable: false,
        cellClass: function (params) {
          try {
            if (params.data.vehicle_assigned == 1) {
              return "light-bg";
            } else {
              return "";
            }
          } catch (error) {}
        },
        params: {
          buttonName: "Run New Plan",
          iconName: "fa fa-tasks",
          onClickFunction: this.onClickRunNew,
        },
        cellRendererSelector: function (params) {
          if (
            params.data.is_consolidated_plan != undefined &&
            params.data.is_consolidated_plan == 1
          ) {
            var rendComponent = {
              component: "Consolidatedbtn",
            };
            return rendComponent;
          } else if (
            params.data.transaction_update == 1 ||
            params.data.vehicle_assigned == 1
          ) {
            var rendComponent = {
              component: "vehicleAssigned",
            };
            return rendComponent;
          } else {
            var rendComponent = {
              component: "GridButton",
            };
            return rendComponent;
          }
        },
      },
    ];
    var columnwithDefsForDropped = [
      {
        headerName: "Dealer",
        field: "dealer_code",
        width: 130,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Demand",
        field: "demand",
        width: 130,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Remarks",
        field: "remarks",
        width: 200,
        filter: true,
        resizable: true,
        editable: false,
      },
    ];
    if (this.state.material_configurations == 1) {
      var templatePath = require("../../assets/json/input_data_with_material_config_template.csv");
    } else {
      var templatePath = require("../../assets/json/input_data_template.csv");
    }
    if (this.state.planRowData !== "" && this.state.planRowData !== undefined) {
      if (
        this.state.planRowData.order_date !== "" &&
        this.state.planRowData.order_date !== undefined
      ) {
        var sorder_date = moment
          .parseZone(this.state.planRowData.order_date)
          .format("DD-MM-YYYY");
      } else {
        var sorder_date = "";
      }
    } else {
      var sorder_date = "";
    }

    var data = this.state.rowData;
    var locValue = this.state.location.value;
    var location_access = JSON.parse(localStorage.getItem("location_access"));
    var filteredData = data;
    // if (locValue === "All") {
    if (location_access !== null) {
      filteredData = data.filter((item) =>
        location_access.includes(item.location_code)
      );
    } else {
      filteredData = data;
    }
    // }

    filteredData.map((item) => {
      item.location_name = pickupLocationCodes[item.location_code];
    });

    var sarOptions = [{ value: "", label: "All" }],
      countries = [];
    var branchOptions = [{ value: "", label: "All" }],
      regions = [];
    var pickupOptions = [{ value: "", label: "All" }],
      pickupCodes = [];
    var dispatchTypes = [
      { label: "All", value: "All" },
      { label: "Secondary", value: "SECONDARY" },
      { label: "Primary", value: "PRIMARY" },
    ];
    var dispatchCodes = [];
    var { dispatchType, country, zone, location } = this.state;
    var boundLocations = this.state.boundLocations;
    var locationAccess = JSON.parse(localStorage.getItem("location_access"));
    var boundLocations = this.state.boundLocations;
    if (location_access !== null) {
      boundLocations = this.state.boundLocations.filter((item) =>
        locationAccess.includes(item.location_code)
      );
    }
    if (dispatchType.value !== "" && dispatchType.value !== "All") {
      boundLocations = boundLocations.filter(
        (item) => item.dispatch_type === dispatchType.value
      );
    }

    boundLocations.map((item) => {
      if (!countries.includes(item.country)) {
        sarOptions.push({ value: item.country, label: item.country });
        countries.push(item.country);
      }
    });
    if (country.value !== "" && country.value !== "All") {
      boundLocations = boundLocations.filter(
        (item) => item.country === country.value
      );
    }
    boundLocations.map((item) => {
      if (!regions.includes(item.location_zone)) {
        branchOptions.push({
          value: item.location_zone,
          label: item.location_zone,
        });
        regions.push(item.location_zone);
      }
    });
    if (zone.value !== "" && zone.value !== "All") {
      boundLocations = boundLocations.filter(
        (item) => item.location_zone === zone.value
      );
    }
    boundLocations.map((item) => {
      if (!pickupCodes.includes(item.location_code)) {
        pickupOptions.push({
          value: item.location_code,
          label: item.location_name,
        });
        pickupCodes.push(item.location_code);
      }
    });
    if (location.value !== "" && location.value !== "All") {
      var loc = boundLocations.filter(
        (item) => item.location_code === location.value
      );
      branchOptions = [{ value: "", label: "All" }];
      sarOptions = [{ value: "", label: "All" }];
      dispatchTypes = [{ value: "", label: "All" }];
      loc.map((item) => {
        branchOptions.push({
          value: item.location_zone,
          label: item.location_zone,
        });
        sarOptions.push({
          value: item.country,
          label: item.country,
        });
        dispatchTypes.push({
          value: item.dispatch_type,
          label: item.dispatch_type,
        });
      });
    }
    if (zone.value !== "" && zone.value !== "All") {
      var loc = boundLocations.filter(
        (item) => item.location_zone === zone.value
      );
      sarOptions = [{ value: "", label: "All" }];
      var sarCodes = [],
        dispatchCodes = [];

      // if (dispatchType.value === "All" || dispatchType.value === "") {
      dispatchTypes = [{ value: "", label: "All" }];
      // }
      dispatchTypes.map((item) => {
        dispatchCodes.push(item.value);
      });
      loc.map((item) => {
        if (!sarCodes.includes(item.country)) {
          sarCodes.push(item.country);
          sarOptions.push({
            value: item.country,
            label: item.country,
          });
        }
        if (!dispatchCodes.includes(item.dispatch_type)) {
          dispatchCodes.push(item.dispatch_type);
          dispatchTypes.push({
            value: item.dispatch_type,
            label: item.dispatch_type,
          });
        }
      });
    }
    if (country.value !== "" && country.value !== "All") {
      var loc = boundLocations.filter((item) => item.country === country.value);
      // if (dispatchType.value === "All" || dispatchType.value === "") {
      dispatchTypes = [{ value: "", label: "All" }];
      // }
      var dispatchCodes = [];
      dispatchTypes.map((item) => {
        dispatchCodes.push(item.value);
      });
      loc.map((item) => {
        if (!dispatchCodes.includes(item.dispatch_type)) {
          dispatchCodes.push(item.dispatch_type);
          dispatchTypes.push({
            value: item.dispatch_type,
            label: item.dispatch_type,
          });
        }
      });
    }
    return (
      <>
        <div class="container-fluid pl-0">
          <div
            className={"animatenow " + this.state.showanimate}
            style={{ textAlign: "center" }}
          >
            <span className="pp-h">Processing Plan. Please wait.....</span>
            <img
              src={require("../../assets/images/r.gif")}
              style={{ width: "85vh" }}
            />
          </div>
          <div className={"lodr " + this.state.showloader}>
            <div className="loaderdiv">
              <div className="loaderwave"></div>
              <div className="loaderwave"></div>
              <div className="loaderwave"></div>
              <div className="loaderwave"></div>
              <div className="loaderwave"></div>
              <div className="loaderwave"></div>
              <div className="loaderwave"></div>
              <div className="loaderwave"></div>
              <div className="loaderwave"></div>
              <div className="loaderwave"></div>
            </div>
            <div className="ldrtext">Loading....</div>
          </div>
          <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
          ></SweetAlert>
          {this.state.alert}
          <div class="row mb-3p">
            <div className="col-sm-4 pt-0">
              <div className="col-sm-12 pl-0">
                <h5 className="fbold col-sm-12 pl-0">
                  Summary Dashboard
                  {/* start  */}
                  {/* <button
                        type="button"
                        className="btn btn-warning float-right font-white"
                        onClick={this.onClickShowUpload}
                      >
                        Run New Plan
                      </button> */}
                  {/* start  */}
                </h5>
              </div>
            </div>
            <div className="col-sm-8">
              {this.state.allTransactionIds.length > 0 ? (
                <div>
                  {this.state.allTransactionIds.length > 0 ? (
                    <CountDownTimer
                      allTransactionIds={this.state.allTransactionIds}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group ">
                <label className="col-form-label f12">Dispatch Type</label>
                <Select
                  placeholder={"Select"}
                  onChange={this.onChangeDispatchType}
                  name="dispatchType"
                  value={this.state.dispatchType}
                  options={dispatchTypes}
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group ">
                <label className="col-form-label f12">SAR</label>
                <Select
                  placeholder={"Select"}
                  onChange={this.locationCountryChange}
                  name="country"
                  value={this.state.country}
                  options={sarOptions}
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group ">
                <label className="col-form-label f12">Branch</label>
                <Select
                  placeholder={"Select"}
                  onChange={this.locationZoneChange}
                  name="zone"
                  value={this.state.zone}
                  options={branchOptions}
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group ">
                <label className="col-form-label f12">Pickup Location</label>
                <Select
                  placeholder={"Select"}
                  onChange={this.locationChanged}
                  name="location"
                  value={this.state.location}
                  options={pickupOptions}
                />
              </div>
            </div>
            {/* start  */}
            {/* <div className="col-md-2">
              <div className="form-group ">
                <label className="col-form-label f12">Order Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="selectDate"
                  id="selectDate"
                  autoComplete="off"
                  onKeyDown={(e) => e.preventDefault()}
                  max={this.getCurrentDateTime()}
                  readonly
                />
              </div>
            </div> */}
            {/* start  */}
            <div className="col">
              <div className="form-group ">
                <label className="col-form-label f12">Generated On</label>
                <input
                  type="date"
                  className="form-control"
                  name="generateDate"
                  id="generateDate"
                  autoComplete="off"
                  onKeyDown={(e) => e.preventDefault()}
                  max={this.getCurrentDateTime()}
                  readonly
                />
              </div>
            </div>
            <div className="col pr-0">
              <button
                type="button"
                onClick={this.onClickGetData}
                className="btn btn-warning mt-30px"
                style={{ marginTop: "35px" }}
              >
                Get Data
              </button>
            </div>
            <div className="col p-0">
              <div
                className="col-sm-12 p-0"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <button
                  type="button"
                  onClick={this.onUploadOBDDetails}
                  className="btn btn-danger mt-30px"
                  style={{ marginTop: "35px" }}
                >
                  Discard OBD
                </button>
              </div>
            </div>
          </div>
          {/* start  */}
          {/* <div class="row mb-10p">
            <div className={"mxheit col-sm-12 "}>
              <h5>Recent Plan Details</h5>
              <div
                id="myGrid"
                style={{ width: "100%", height: "40vh" }}
                className={"ag-theme-balham"}
              >
                <AgGridReact
                  // modules={this.state.modules}
                  columnDefs={columnwithDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={filteredData}
                  enableCharts={false}
                  onGridReady={this.onGridReady}
                  onGridState={this.onGridState}
                  frameworkComponents={this.state.frameworkComponents}
                  statusBar={this.state.statusBar}
                  sideBar={this.state.sideBar}
                  stopEditingWhenGridLosesFocus={true}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                  gridOptions={{
                    context: { componentParent: this },
                  }}
                  enableRangeSelection={true}
                  masterDetail={true}
                  detailCellRendererParams={this.state.detailCellRendererParams}
                  rowClassRules={this.state.rowClassRules}
                />
              </div>
            </div>
          </div> */}
          {/* start  */}
          <div class="row mb-10p">
            <div className={"mxheit col-sm-12 "}>
              <h5>Plan Details</h5>
              <div
                id="myGrid1"
                style={{ width: "100%", height: "70vh" }}
                className={"ag-theme-balham"}
              >
                <AgGridReact
                  // modules={this.state.modules}
                  columnDefs={columnwithDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={filteredData}
                  enableCharts={false}
                  onGridReady={this.onGridReady}
                  onGridState={this.onGridState}
                  frameworkComponents={this.state.frameworkComponents}
                  statusBar={this.state.statusBar}
                  sideBar={this.state.sideBar}
                  stopEditingWhenGridLosesFocus={true}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                  gridOptions={{
                    context: { componentParent: this },
                  }}
                  enableRangeSelection={true}
                  enableCellContextMenu={true}
                  // allowContextMenuWithControlKey={true}
                  suppressContextMenu={false}
                  masterDetail={true}
                  detailCellRendererParams={this.state.detailCellRendererParams}
                  rowClassRules={this.state.rowClassRules}
                />
              </div>
            </div>
          </div>
          <div
            className={"overlay-block " + this.state.overlayBlock}
            onClick={this.hideSlideBlock}
          ></div>
          <div className={"sliderBlock2 " + this.state.showSlideBlockUpload}>
            <h5 className="crd-bg p-10p">Drop Details</h5>
            <div className="col-sm-12">
              <div
                id="myGrid2"
                style={{ width: "100%", height: "80vh" }}
                className="col-sm-12 ag-theme-balham dropdown"
              >
                <AgGridReact
                  // modules={this.state.modules}
                  rowData={this.state.inputDetails}
                  columnDefs={inputDetailscolsDefs}
                  gridOptions={{ context: { componentParent: this } }}
                  defaultColDef={this.state.defaultColDef}
                  frameworkComponents={this.state.frameworkComponents}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                  enableRangeSelection={true}
                />
              </div>
            </div>
          </div>

          {/* slider area */}
          <div
            className={"sliderBlock2 " + this.state.sliderRso}
            style={{ overflow: "auto" }}
          >
            <h5 className="crd-bg p-10p">
              Upload OBD Details
              <span
                className="float-right closebtn"
                style={{ marginRight: "25px" }}
                onClick={this.hideSlideBlock.bind(this)}
              >
                X
              </span>
            </h5>
            <div
              className={"row"}
              style={{ padding: "10px 10px", width: "100%" }}
            >
              <div className="col-xl-12 col-lg-12 form-group">
                <a
                  href={require("../../assets/json/OBD_Upload_CSV_Download_sample_template4.csv")}
                  target="_blank"
                  className="btn btn-warning"
                >
                  Download Template
                </a>
              </div>
              <div className="theme-form col-xl-12 col-lg-12">
                <form action="" onSubmit={this.onSubmitBulkUpload.bind(this)}>
                  <div className="col-xl-12 col-lg-12 row form-group">
                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                    <input
                      type="file"
                      name="uploadfile"
                      id="uploadfile"
                      className="form-control"
                      onChange={this.changeFileHandlerFc.bind(this)}
                      required
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 form-group">
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </form>
                {this.state.show && (
                  <div>
                    <ul
                      style={{
                        listStyle: "none",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {this.state.errors
                        ? this.state.errors.map((error, index) => (
                            <li
                              key={index}
                              style={{
                                color: "red",
                                fontSize: "16px",
                                marginBottom: "8px",
                              }}
                            >
                              {error.message}
                            </li>
                          ))
                        : ""}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div className={"dataLoadpage " +(this.state.loadshow)}></div> */}
          <div className={"loader " + this.state.loadshow}></div>
          <div
            className={"overlay-part " + this.state.overly}
            onClick={this.hideSlideBlock.bind(this)}
          ></div>

          <Modal
            closeOnOverlayClick={false}
            open={this.state.openSLA}
            onClose={this.handelPickupDropModelCancel}
            classNames={{ modal: "pickupdrop-modelClass" }}
          >
            <div className="container-fluid">
              <div className="mappg mprel">
                <div className="row">
                  <div className={"col-xl-8 col-lg-8 "}>
                    <form
                      method="POST"
                      onSubmit={this.executePlanNow.bind(this)}
                    >
                      <div className="form-group">
                        <label className="col-form-label f12">Order Date</label>
                        {sorder_date}
                      </div>
                      <div className="form-group">
                        <label className="col-form-label f12">
                          Select Planning Duration
                        </label>
                        <Select
                          placeholder={"Select"}
                          onChange={this.slaChanged.bind(this)}
                          name="sla"
                          value={this.state.pla}
                          options={[
                            { value: "", label: "Select" },
                            { value: 1, label: "Single Day" },
                            { value: 2, label: "Multiple Days" },
                          ]}
                        />
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="col-form-label f12">
                            From Date
                          </label>
                          <input
                            type="text"
                            id="fDate"
                            name="fDate"
                            className="form-control"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="col-form-label f12">To Date</label>
                          <input
                            type="text"
                            id="tDate"
                            name="tDate"
                            className="form-control"
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <button
                          type="submit"
                          className="btn btn-warning mt-30p"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div className={"loader " + this.state.loadshow}></div>

        <Modal
          closeOnOverlayClick={false}
          open={this.state.modalForRunNew}
          onClose={this.handelUpdateModelCancel}
          classNames={{ modal: "runNew-modelClass runNewPlan-position" }}
        >
          <div className="col-sm-12 text-center">
            <div className="mt-10p mb-10p">
              <h6 className="text-center">
                Are you sure you want to Proceed ?
              </h6>
            </div>

            <div className=" mt-20p" style={{ marginTop: "22px" }}>
              <button
                type="button"
                onClick={this.planNewDispatch}
                className="btn tbtn assign-btn"
              >
                Yes
              </button>
              <button
                type="button"
                className="btn tbtn cancel-btn ass-cancel-btn"
                onClick={this.handelUpdateModelCancel}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
$(function () {
  $(".datepicker").datepicker({
    dateFormat: "dd-mm-yy",
    changeYear: true,
  });
});

function GetSortAscOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function GetSortDescOrder(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}
function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
